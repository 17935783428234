import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { getAllRtspServers } from '../../api/RtspServerApi';
import styles from './RTSPServersList.module.scss';

export const RTSPServersList = ({ permission }) => {
  const rtspServerApi = useApi(getAllRtspServers);

  const [limit, setLimit] = useState(15);
  const [rtspServers, setRtspServers] = useState([]);
  const [rtspServersCount, setRtspServersCount] = useState(0);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const getRTSPServers = async (offset = 0) => {
    const [result, count] = await rtspServerApi.sendRequest(limit, offset);
    setRtspServers(result);
    setRtspServersCount(Math.ceil(count / limit));
  };

  useEffect(() => {
    getRTSPServers(page);
  }, []);

  const handleChange = (event, value) => {
    setPage(value - 1);
    getRTSPServers(value - 1);
  };

  return (
    <>
      {permission?.create && (
        <Button
          color="success"
          variant="outlined"
          sx={{ mt: 3 }}
          disabled={!permission?.create}
          onClick={() => {
            navigate(URLS.VIDEO_SERVERS_RTSP + '/create');
          }}
        >
          ДОБАВИТЬ RTSP СЕРВЕР
        </Button>
      )}

      <Table
        sx={{
          minWidth: 650,
          border: 'solid 1px #eee',
          borderRadius: '5px',
          mt: 3,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell alignt="left">
              <b>Название</b>
            </TableCell>
            <TableCell align="left">
              <b>URL-адрес API</b>
            </TableCell>
            <TableCell align="left">
              <b>Дата создания</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rtspServers.map((server) => (
            <TableRow
              className={styles?.RTSPServersList__Row}
              onClick={() =>
                navigate(URLS.VIDEO_SERVERS_RTSP + `/${server.id}`)
              }
              key={server.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {server?.name}
              </TableCell>
              <TableCell align="left">{server?.apiUrl}</TableCell>
              <TableCell align="left">
                {format(new Date(server.created), 'dd.MM.yyyy HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {rtspServersCount > 0 && (
        <Pagination
          count={rtspServersCount}
          page={page}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
        />
      )}
    </>
  );
};
